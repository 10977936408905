<form [formGroup]="form" (ngSubmit)="onSubmit()" [hidden]="!showForm" class="entity-form">
    <div class="row">
        <div class="col-sm-12 col-md-6" [hidden]="editMode">
            <button kendoButton type="submit" [disabled]="!form.valid" [primary]="true" [icon]="'save'">Create User</button>
        </div>
        <div class="col-sm-12 col-md-6" [hidden]="!editMode">
            <kendo-buttongroup>
                <button kendoButton type="button" (click)="onSubmit()" [primary]="true" [disabled]="!form.valid" [icon]="'save'">Save</button>
            </kendo-buttongroup>
        </div>
        <div class="col-sm-12">
            <hr/>
        </div>
    </div>
    <app-validation-errors></app-validation-errors>
    <kendo-tabstrip>
        <kendo-tabstrip-tab [title]="'General'" [selected]="true">
            <ng-template kendoTabContent>
                <div class="row">
                    <div class="col-sm-12 col-md-6" [hidden]="!editMode">
                        <label for="id" class="col-form-label col-form-label-sm">User Id</label>
                        <input kendoTextBox readonly add-required-asterisk formControlName="id" id="id" class="form-control" placeholder="User Id" />
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <label for="username" class="col-form-label col-form-label-sm">User Name</label>
                        <input kendoTextBox maxlength="50" readonly add-required-asterisk formControlName="username" id="username" class="form-control" placeholder="User Name" />
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <label for="firstName" class="col-form-label col-form-label-sm">First Name</label>
                        <input kendoTextBox maxlength="50"add-required-asterisk formControlName="firstName" id="firstName" class="form-control" placeholder="First Name" />
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <label for="lastName" class="col-form-label col-form-label-sm">Last Name</label>
                        <input kendoTextBox maxlength="50" add-required-asterisk formControlName="lastName" id="lastName" class="form-control" placeholder="Last Name" />
                    </div>
                    <div class="col-xs-12 col-md-6">
                        <label for="emailAddress" class="col-form-label col-form-label-sm">Email Address</label>
                        <input kendoTextBox maxlength="255" add-required-asterisk type="email" formControlName="emailAddress" id="emailAddress" class="form-control" placeholder="Email Address">
                    </div>
                    <div class="col-xs-12 col-md-6">
                        <label for="phoneNumber" class="col-form-label col-form-label-sm">Phone Number</label>
                        <app-phone-number add-required-asterisk formControlName="phoneNumber" [elementId]="'phoneNumber'"></app-phone-number>
                    </div>
                </div>
                <app-password-confirmation (onFormGroupChange)="onFormGroupChangeEvent($event)"></app-password-confirmation>
            </ng-template>
        </kendo-tabstrip-tab>
    </kendo-tabstrip>
</form>