import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { KendoModule } from '@kendo/kendo.module';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ExcelModule, GridModule, PDFModule } from '@progress/kendo-angular-grid';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { MomentModule } from 'ngx-moment';
import { DashboardReportsComponent } from '../components/dashboard/dashboard-reports/dashboard-reports.component';
import { CheckboxButtonListComponent } from './components/checkbox-button-list';
import { CountryAndProvinceSelectorComponent } from './components/country-and-province-selector';
import { EntityDescriptionComponent } from './components/entity-description/entity-description.component';
import { AutoPilotGrid } from './components/grid/grid.component';
import { LocationSelectorComponent } from './components/location-selector/location-selector.component';
import { MetadataComponent } from './components/metadata';
import { MultiDateAndTimeSelectorComponent } from './components/multi-date-and-time-selector';
import { PasswordConfirmationComponent } from './components/password-confirmation/password-confirmation.component';
import { QuantityDisplayComponentComponent } from './components/quantity-display-component/quantity-display-component.component';
import { RadioButtonListComponent } from './components/radio-button-list';
import { SampleHtmlComponent } from './components/sample-html';
import { ErrorMessageComponent } from './components/validation-errors';
import { AfterValueChangedDirective } from './directives';
import { MarkAsteriskDirective } from './directives/mark-asterisk.directive';
import { DefaultPipe } from './pipes';
import { EmailPipe } from './pipes/email.pipe';
import { FilterValuesPipe } from './pipes/filter-values.pipe';
import { PhonePipe } from './pipes/phone.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { WebsitePipe } from './pipes/website.pipe';
import { AccountMenuComponent } from './components/account-menu/account-menu.component';
import { ManikinAndTrainerInfoHeaderComponent } from './components/manikin-and-trainer-info-header/manikin-and-trainer-info-header.component';
import { PhoneNumberComponent } from './components/phone-number/phone-number.component';
import { QRCodeScannerComponent } from './components/qrcode-scanner/qrcode-scanner.component';

const COMPONENT_LIST = [
  CheckboxButtonListComponent,
  CountryAndProvinceSelectorComponent,
  EntityDescriptionComponent,
  LocationSelectorComponent,
  MetadataComponent,
  MultiDateAndTimeSelectorComponent,
  PasswordConfirmationComponent,
  QuantityDisplayComponentComponent,
  RadioButtonListComponent,
  SampleHtmlComponent,
  AfterValueChangedDirective,
  MarkAsteriskDirective,
  EmailPipe,
  FilterValuesPipe,
  PhonePipe,
  SafePipe,
  WebsitePipe,
  DefaultPipe,
  DashboardReportsComponent,
  ErrorMessageComponent,
  AccountMenuComponent,
  ManikinAndTrainerInfoHeaderComponent,
  PhoneNumberComponent,
  QRCodeScannerComponent
];

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    DropDownsModule,
    RouterModule,
    ChartsModule,
    LayoutModule,
    GridModule,
    PDFModule,
    ExcelModule,
    MomentModule,
    KendoModule,
    AutoPilotGrid
  ],

  declarations: [...COMPONENT_LIST],

  exports: [...COMPONENT_LIST, AutoPilotGrid]
})
export class SharedModule {}
