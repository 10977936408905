<form [formGroup]="form" (ngSubmit)="onSubmit(false)" [hidden]="!showForm" class="entity-form">
    <div class="row">
        <div class="col-sm-12 col-md-6" [hidden]="editMode">
            <button kendoButton
              type="submit"
              [disabled]="!form.valid"
              [primary]="true"
              [icon]="'save'">
              Create {{this.GetSingleEntityName()}}
            </button>
        </div>
        <div class="col-sm-12 col-md-6" [hidden]="!editMode">
            <kendo-buttongroup>
                <!--<button kendoButton type="submit" [disabled]="!form.valid" [primary]="true" [icon]="'save'">Save and Continue</button>-->
                <button kendoButton
                  type="button"
                  [primary]="true"
                  [disabled]="!form.valid"
                  [icon]="'save'"
                  (click)="submitAndClose()">
                  Save and Close
                </button>
                <button kendoButton
                  type="button"
                  [primary]="false"
                  [icon]="'cancel-circle'"
                  (click)="resetForm()">
                  Cancel
                </button>
            </kendo-buttongroup>
        </div>
        <div class="col-sm-12 col-md-6" [hidden]="!editMode">
            <div class="float-end">
                <kendo-buttongroup>
                    <!--<button kendoButton type="button" (click)="disable()" [icon]="'cancel-circle'">Disable {{this.GetSingleEntityName()}}</button>
                  <button kendoButton type="button" (click)="delete()" [icon]="'delete'">Delete {{this.GetSingleEntityName()}}</button>-->
                </kendo-buttongroup>
                <div kendoDialogContainer></div>
            </div>
        </div>
        <div class="col-sm-12" [hidden]="true">
            <kendo-buttongroup>
                <button kendoButton
                  type="button"
                  [primary]="true"
                  [icon]="'user'">
                  View Users
                </button>
            </kendo-buttongroup>
        </div>
        <div class="col-sm-12">
            <hr/>
        </div>
    </div>

    <app-validation-errors></app-validation-errors>
    <input type="hidden" id="id" formControlName="id" />

    <kendo-tabstrip>
        <kendo-tabstrip-tab [title]="'General'" [selected]="true">
            <ng-template kendoTabContent>
                <div class="row">
                    <div class="col-sm-12">
                        <label for="name" class="col-form-label col-form-label-sm">
                          Vendor Name
                        </label>
                        <input kendoTextBox
                          formControlName="vendorName"
                          add-required-asterisk
                          id="name"
                          class="form-control"
                          placeholder="Vendor Name" />
                        <validation-error-message [control]="vendorName"></validation-error-message>
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <label for="techSupportPhone" class="col-form-label col-form-label-sm">
                          Tech Support Phone
                        </label>
                        <app-phone-number formControlName="techSupportPhone" [elementId]="'techSupportPhone'"></app-phone-number>
                        <validation-error-message [control]="techSupportPhone"></validation-error-message>
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <label for="techSupportEmail" class="col-form-label col-form-label-sm">
                          Tech Support Email
                        </label>
                        <input kendoTextBox
                          type="email"
                          formControlName="techSupportEmail"
                          add-required-asterisk
                          id="techSupportEmail"
                          class="form-control"
                          placeholder="Tech Support Email" />
                        <validation-error-message [control]="techSupportEmail"></validation-error-message>
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <label for="techSupportWebsite" class="col-form-label col-form-label-sm">
                          Tech Support Website
                        </label>
                        <input kendoTextBox
                          type="url"
                          formControlName="techSupportWebsite"
                          add-required-asterisk
                          id="techSupportWebsite"
                          class="form-control"
                          placeholder="Tech Support Website" />
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <label for="customerServicePhone" class="col-form-label col-form-label-sm">
                          Customer Service Phone
                        </label>
                        <app-phone-number formControlName="customerServicePhone" [elementId]="'customerServicePhone'"></app-phone-number>
                        <validation-error-message [control]="customerServicePhone"></validation-error-message>
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <label for="customerServiceEmail" class="col-form-label col-form-label-sm">
                          Customer Service Email
                        </label>
                        <input kendoTextBox
                          type="email"
                          formControlName="customerServiceEmail"
                          add-required-asterisk
                          id="customerServiceEmail"
                          class="form-control"
                          placeholder="Customer Service Email" />
                        <validation-error-message [control]="customerServiceEmail"></validation-error-message>
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <label for="customerServiceWebsite" class="col-form-label col-form-label-sm">
                          Customer Service Website
                        </label>
                        <input kendoTextBox
                          type="url"
                          formControlName="customerServiceWebsite"
                          add-required-asterisk
                          id="customerServiceWebsite"
                          class="form-control"
                          placeholder="Customer Service Website" />
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <label for="salesRepName" class="col-form-label col-form-label-sm">
                          Sales Rep Name
                        </label>
                        <input kendoTextBox
                          formControlName="salesRepName"
                          add-required-asterisk
                          id="salesRepName"
                          class="form-control"
                          placeholder="Sales Rep Name" />
                        <validation-error-message [control]="salesRepName"></validation-error-message>
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <label for="salesRepPhone" class="col-form-label col-form-label-sm">
                          Sales Rep Phone
                        </label>
                        <app-phone-number formControlName="salesRepPhone" [elementId]="'salesRepPhone'"></app-phone-number>
                        <validation-error-message [control]="salesRepPhone"></validation-error-message>
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <label for="salesRepEmail" class="col-form-label col-form-label-sm">
                          Sales Rep Email
                        </label>
                        <input kendoTextBox
                          type="email"
                          formControlName="salesRepEmail"
                          add-required-asterisk
                          id="salesRepEmail"
                          class="form-control"
                          placeholder="Sales Rep Email" />
                        <validation-error-message [control]="salesRepEmail"></validation-error-message>
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <label for="salesWebsite" class="col-form-label col-form-label-sm">
                          Sales Website
                        </label>
                        <input kendoTextBox
                          type="url"
                          formControlName="salesWebsite"
                          add-required-asterisk
                          id="salesWebsite"
                          class="form-control"
                          placeholder="Sales Website" />
                    </div>
                    <div class="col-md-12">
                        <label for="categories" class="col-form-label col-form-label-sm">
                          Categories
                        </label>
                        <kendo-multiselect
                            textField="categoryName"
                            valueField="id"
                            [allowCustom]="true"
                            [data]="listItems"
                            formControlName="categories"
                            [valueNormalizer]="valueNormalizer"
                        ></kendo-multiselect>
                    </div>
                    <div class="col-md-12">
                        <label for="notes" class="col-form-label col-form-label-sm">
                          Notes
                        </label>
                        <textarea kendoTextBox
                          type="text"
                          add-required-asterisk
                          formControlName="notes"
                          id="notes"
                          class="form-control"
                          placeholder="Notes">
                        </textarea>
                    </div>
                </div>
            </ng-template>
        </kendo-tabstrip-tab>

        <kendo-tabstrip-tab [title]="'Metadata'" [disabled]="!editMode">
            <ng-template kendoTabContent>
                <app-metadata [metadata]="dataModel"></app-metadata>
            </ng-template>
        </kendo-tabstrip-tab>
    </kendo-tabstrip>
</form>
