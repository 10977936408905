import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import {
  CreateManikinMakeAndModelCommand,
  CreateManikinMakeAndModelCommandResult,
  EnumListResult,
  ManikinInventoryItemService,
  ManikinMakeAndModelService,
  ManikinOrTrainerBaseType,
  OrganizationService,
  ReadManikinMakeAndModelCommandResult,
  UpdateManikinMakeAndModelCommand,
  UpdateManikinMakeAndModelCommandResult,
  VendorService,
  VendorViewModel
} from '@wo-api/index';
import { EntityGlobals, UrlRegex, ValidationMaxStringLength } from '@wo-app/app.global';
import { BreadcrumbsService } from '@wo-app/breadcrumbs/shared/services';
import { ToastService } from '@wo-app/core/common/toast-message/shared/services';
import { ImpersonationService } from '@wo-app/core/services';
import { EntityBaseComponent } from '@wo-app/shared/models';
import { patternValidator } from '@wo-app/shared/utils';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-manikin-make-and-model-detail',
  templateUrl: './manikin-make-and-model-detail.component.html',
  styleUrls: ['./manikin-make-and-model-detail.component.scss']
})
export class ManikinMakeAndModelDetailComponent extends EntityBaseComponent implements OnInit {
  override showForm: boolean;
  readManikinMakeAndModelCommandResult: ReadManikinMakeAndModelCommandResult = {};
  dataModel: ReadManikinMakeAndModelCommandResult;
  updateManikinMakeAndModelCommand: UpdateManikinMakeAndModelCommand = {};
  createManikinMakeAndModelCommand: CreateManikinMakeAndModelCommand = {};
  form: FormGroup;
  originalFormState: FormGroup;
  fromKey: string;
  organizations: any[];
  selectedOrganizationId: string;
  skinTones: Observable<EnumListResult>;
  statuses: Observable<EnumListResult>;
  genders: Observable<EnumListResult>;
  manikinTypes: Observable<EnumListResult>;
  trainerTypes: Observable<EnumListResult>;
  manikinOrTrainerBaseTypes: Observable<EnumListResult>;
  $vendors: Observable<any>;
  Manikin: ManikinOrTrainerBaseType = ManikinOrTrainerBaseType.Manikin;
  Trainer: ManikinOrTrainerBaseType = ManikinOrTrainerBaseType.Trainer;

  get modelName() {
    return this.form.get('modelName');
  }

  get modelNumber() {
    return this.form.get('modelNumber');
  }

  get description() {
    return this.form.get('description');
  }

  get notes() {
    return this.form.get('notes');
  }

  get manualLink() {
    return this.form.get('manualLink');
  }

  get trainingLink() {
    return this.form.get('trainingLink');
  }

  public vendorDefaultItem: VendorViewModel = {
    id: -1,
    vendorName: 'Select an item...'
  };

  constructor(
    private loadingBar: LoadingBarService,
    private logger: NGXLogger,
    private impersonationService: ImpersonationService,
    private organizationService: OrganizationService,
    private manikinMakeAndModelService: ManikinMakeAndModelService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private dialogService: DialogService,
    private toastService: ToastService,
    private vendorService: VendorService,
    private manikinInventoryItemService: ManikinInventoryItemService,
    breadcrumbService: BreadcrumbsService,
    titleService: Title
  ) {
    super(EntityGlobals.MANIKIN_MAKES_AND_MODELS, router, route, impersonationService, logger, breadcrumbService, titleService);
    this.$vendors = this.vendorService.list();
    this.manikinTypes = this.manikinInventoryItemService.manikinTypes();
    this.manikinOrTrainerBaseTypes = this.manikinInventoryItemService.manikinOrTrainerBaseTypes();
    this.trainerTypes = this.manikinInventoryItemService.trainerTypes();
  }

  private _createForm() {
    this.form = this.fb.group({
      id: [''],
      modelName: ['', [Validators.maxLength(ValidationMaxStringLength.Default)]],
      manufacturerName: [''],
      manufacturerVendorId: ['', [Validators.required]],
      modelNumber: ['', [Validators.maxLength(ValidationMaxStringLength.Default)]],
      manikinOrTrainerBaseType: [''],
      manikinType: [null],
      trainerType: [null],
      description: ['', [Validators.maxLength(ValidationMaxStringLength.Long)]],
      notes: ['', [Validators.maxLength(ValidationMaxStringLength.Long)]],
      manualLink: ['', [Validators.maxLength(ValidationMaxStringLength.Long), patternValidator(UrlRegex)]],
      trainingLink: ['', [Validators.maxLength(ValidationMaxStringLength.Long), patternValidator(UrlRegex)]],
      created: [''],
      createByUsername: [''],
      createdByUserId: [''],
      lastUpdated: [''],
      lastUpdatedByUsername: [''],
      lastUpdatedByUserId: ['']
    });
    this.originalFormState = this.form;
  }

  private _getData() {
    this.loadingBar.useRef('router').start();
    this.manikinMakeAndModelService.read(this.id).subscribe((result: ReadManikinMakeAndModelCommandResult) => {
      this.loadingBar.useRef('router').complete();
      this._convertServerDataToFormModel(result);
      this.showForm = true;
    });
  }

  private _convertServerDataToFormModel(result: ReadManikinMakeAndModelCommandResult) {
    this.dataModel = result;
    this.logger.debug(this.dataModel);
    this.form.patchValue(this.dataModel);
    this.logger.debug(this.originalFormState.pristine, this.originalFormState);
    if (this.originalFormState.pristine) {
      this.logger.debug('Setting Original Form State Value');
      this.originalFormState.patchValue(this.dataModel);
    }
  }

  private _adjustValueForDependentFields(formValue: FormGroup): any {
    if (formValue.get('manikinOrTrainerBaseType').value == this.Manikin) {
      formValue.get('trainerType').setValue(null);
    }
    if (formValue.get('manikinOrTrainerBaseType').value == this.Trainer) {
      formValue.get('manikinType').setValue(null);
    }
    return formValue.value;
  }

  public ngOnInit() {
    this._createForm();
    this.showForm = false;
    this.fromKey = 'FormModel';
    if (this.editMode) {
      this._getData();
    } else {
      this.showForm = true;
    }
  }

  public resetForm() {
    this.logger.debug(this.originalFormState);
    this.form.reset(this.originalFormState.value);
    this.routeToEntityList();
  }

  public onSubmit(closeOnSuccess) {
    // If the Form is in Edit Mode, Update Object
    if (this.editMode) {
      this.updateManikinMakeAndModelCommand = this._adjustValueForDependentFields(this.form);
      this.logger.debug(this.updateManikinMakeAndModelCommand);
      this.manikinMakeAndModelService
        .update(this.updateManikinMakeAndModelCommand.id.toString(), this.updateManikinMakeAndModelCommand)
        .subscribe((result: UpdateManikinMakeAndModelCommandResult) => {
          this.logger.debug(result);
          if (closeOnSuccess) {
            this.routeToEntityList();
          } else {
            this._convertServerDataToFormModel(result);
            this.toastService.success('Success!', 'This item has been saved.');
          }
        });
    } else {
      // Otherwise, create a new object
      this.createManikinMakeAndModelCommand = this._adjustValueForDependentFields(this.form);
      this.logger.debug(this.createManikinMakeAndModelCommand);
      this.manikinMakeAndModelService
        .create(this.createManikinMakeAndModelCommand)
        .subscribe((result: CreateManikinMakeAndModelCommandResult) => {
          this._convertServerDataToFormModel(result);
          this.routeToEntityList();
          this.editMode = true;
          this.toastService.success('Success!', 'This item has been saved.');
        });
    }
  }

  public submitAndClose() {
    this.onSubmit(true);
  }

  public disable() {}

  public delete() {
    const dialog: DialogRef = this.dialogService.open({
      title: 'Please confirm',
      content: 'Are you sure you want to delete this manikinMakeAndModel?',
      actions: [{ text: 'No' }, { text: 'Yes', primary: true }],
      width: 450,
      height: 200,
      minWidth: 250
    });

    dialog.result.subscribe(result => {
      if (result instanceof DialogCloseResult) {
        this.logger.debug('close');
      } else {
        this.logger.debug('action', result);
      }
      this.toastService.success('Oops.', 'This item has been saved.');
    });
  }
}
