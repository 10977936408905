<form [formGroup]="form" (ngSubmit)="onSubmit(false)" [hidden]="!showForm" class="entity-form">
    <div class="row">
        <div class="col-xs-12 col-md-6" [hidden]="editMode">
            <button kendoButton
              type="submit"
              [disabled]="!form.valid"
              [primary]="true"
              [icon]="'save'">
              Create User
            </button>
        </div>
        <div class="col-xs-12 col-md-6" [hidden]="!editMode">
            <kendo-buttongroup>
                <button kendoButton
                  type="button"
                  [primary]="true"
                  [disabled]="!form.valid"
                  [icon]="'save'"
                  (click)="submitAndClose()">
                  Save and Close
                </button>
                <button kendoButton
                  type="button"
                  [primary]="false"
                  [icon]="'cancel-circle'"
                  (click)="resetForm()">
                  Cancel
                </button>
            </kendo-buttongroup>
        </div>
        <div class="col-xs-12 col-md-6" [hidden]="!editMode">
            <div class="float-end">
            </div>
        </div>
        <div class="col-xs-12">
            <hr/>
        </div>
    </div>

    <app-validation-errors></app-validation-errors>
    <input type="hidden" id="id" formControlName="id" />

    <kendo-tabstrip>
        <kendo-tabstrip-tab [title]="'General'" [selected]="true">
            <ng-template kendoTabContent>
                <div class="row">
                    <div class="col-xs-12 col-md-6">
                        <label for="id" class="col-form-label col-form-label-sm" [hidden]="!editMode">
                          User Id
                        </label>
                        <input kendoTextBox readonly add-required-asterisk
                          [hidden]="!editMode"
                          formControlName="id"
                          id="id"
                          class="form-control"
                          placeholder="User Id" />
                    </div>
                    <div class="col-xs-12 col-md-6">
                        <label for="username" class="col-form-label col-form-label-sm">
                          User Name
                        </label>
                        <input kendoTextBox add-required-asterisk required
                          formControlName="username"
                          id="username"
                          maxlength="50"
                          class="form-control"
                          placeholder="User Name" />
                        <validation-error-message [control]="userNameCtrl"></validation-error-message>
                    </div>
                    <div class="col-xs-12 col-md-6">
                        <label for="firstName" class="col-form-label col-form-label-sm">
                          First Name
                        </label>
                        <input kendoTextBox
                          formControlName="firstName"
                          id="firstName"
                          maxlength="50"
                          class="form-control"
                          placeholder="First Name" />
                        <validation-error-message [control]="firstName"></validation-error-message>
                    </div>
                    <div class="col-xs-12 col-md-6">
                        <label for="lastName" class="col-form-label col-form-label-sm">
                          Last Name
                        </label>
                        <input kendoTextBox
                          formControlName="lastName"
                          id="lastName"
                          maxlength="50"
                          class="form-control"
                          placeholder="Last Name" />
                        <validation-error-message [control]="lastName"></validation-error-message>
                    </div>
                    <div class="col-xs-12 col-md-6">
                        <label for="emailAddress" class="col-form-label col-form-label-sm">
                          Email Address
                        </label>
                        <input kendoTextBox add-required-asterisk required
                          type="email"
                          formControlName="emailAddress"
                          id="emailAddress"
                          maxlength="255"
                          class="form-control"
                          placeholder="Email Address">
                        <validation-error-message [control]="emailAddressCtrl"></validation-error-message>
                    </div>
                    <div class="col-xs-12 col-md-6">
                        <label for="phoneNumber" class="col-form-label col-form-label-sm">
                          Phone Number
                        </label>
                        <app-phone-number add-required-asterisk required formControlName="phoneNumber" [elementId]="'phoneNumber'"></app-phone-number>
                        <validation-error-message [control]="phoneNumberCtrl"></validation-error-message>
                    </div>
                </div>
                <app-checkbox-button-list
                  formControlName="globalRoles"
                  [hidden]="hideGlobalRolesSelector"
                  [orientation]="'horizontal'"
                  [data]="globalRoles"
                  [groupName]="'globalRoles'"
                  [showInline]="true"
                  [dataLabelField]="'name'"
                  [dataValueField]="'value'">
                </app-checkbox-button-list>
                <app-checkbox-button-list
                  formControlName="tenantRoles"
                  [orientation]="'horizontal'"
                  [data]="tenantRoles"
                  [groupName]="'tenantRoles'"
                  [showInline]="true"
                  [dataLabelField]="'name'"
                  [dataValueField]="'value'">
                </app-checkbox-button-list>
            </ng-template>
        </kendo-tabstrip-tab>
    </kendo-tabstrip>
</form>
