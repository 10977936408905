import { NbEvaIconsModule } from '@nebular/eva-icons';

// Add Angular Code Items
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule, SwUpdate } from '@angular/service-worker';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { AppComponent } from './app.component';
import { AppRoutingModule, routingComponents } from './app.routing';

// Add Services
import { ApplicationUpdateService } from './core/services/application-update-service.service';

// Add Modules
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { MomentModule } from 'ngx-moment';
import { BreadcrumbsModule } from './breadcrumbs/breadcrumbs.module';

// Add Other Miscellaneous
import { WebBluetoothModule } from '@manekinekko/angular-web-bluetooth';
import { NbAlertModule, NbButtonModule, NbCheckboxModule, NbInputModule, NbThemeModule } from '@nebular/theme';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EditorModule } from '@progress/kendo-angular-editor';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import { UploadsModule } from '@progress/kendo-angular-upload';
import { ApiModule } from '@wo-api/api.module';
import { Configuration } from '@wo-api/configuration';
import 'hammerjs';
import { ColorPickerModule } from 'ngx-color-picker';
import { ModalDialogModule } from 'ngx-modal-dialog';
import { KendoModule } from '../lib/kendo/kendo.module';
import { AuthModule } from './auth/auth.module';
import { CoreModule } from './core/core.module';
import { ApplicationOfflineService } from './core/services/application-offline.service';
import { BluetoothPrinterService } from './core/services/bluetooth-printer.service';
import { CachedDataService } from './core/services/cached-data.service';
import { ConfigLoaderService, configurationServiceInitializerFactory } from './core/services/config-loader.service';
import { InventoryImportSignalRService } from './dashboard/services/inventory-import-signal-r.service';
import { SharedModule } from './shared/shared.module';
import { ZXingScannerModule } from '@zxing/ngx-scanner';

@NgModule({
  imports: [
    NbEvaIconsModule,
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: true }),
    LoggerModule.forRoot({
      level: NgxLoggerLevel.DEBUG,
      serverLogLevel: NgxLoggerLevel.ERROR,
      enableSourceMaps: true, // <-- THIS IS REQUIRED, to make "line-numbers" work in SourceMap Object defition (without evalSourceMap)
      disableConsoleLogging: false
    }),
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BreadcrumbsModule,
    ZXingScannerModule,
    MomentModule,
    NgbModule,
    BrowserAnimationsModule,
    KendoModule,
    ModalDialogModule.forRoot(),
    LoadingBarModule,
    NbThemeModule.forRoot(),
    NbAlertModule,
    NbInputModule,
    NbButtonModule,
    NbCheckboxModule,
    ProgressBarModule,
    AppRoutingModule,
    ColorPickerModule,
    UploadsModule,
    EditorModule,
    WebBluetoothModule.forRoot({
      enableTracing: true // or false, this will enable logs in the browser's console
    }),
    AuthModule,
    CoreModule,
    SharedModule,
    ApiModule.forRoot(apiConfig)
  ],
  declarations: [AppComponent, routingComponents],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: configurationServiceInitializerFactory,
      deps: [ConfigLoaderService],
      multi: true
    },
    BluetoothPrinterService,
    SwUpdate,
    Title,
    ApplicationUpdateService,
    CachedDataService,
    InventoryImportSignalRService,
    ApplicationOfflineService
  ],
  bootstrap: [AppComponent]
} as any)
export class AppModule {}

export function apiConfig() {
  let apiUrl = GetApiUrl();
  return new Configuration({
    basePath: GetApiUrl()
  });
}

export function GetApiUrl() {
  const jsonFile = `assets/app-config.json`; //path to config file
  var request = new XMLHttpRequest();
  request.open('GET', jsonFile, false); // get app settings
  request.send(null);
  const response = JSON.parse(request.responseText);
  return response.ConfigFileUrl;
}
