import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { EntityGlobals } from '@wo-app/app.global';
import { ImpersonationService } from '@wo-app/core/services';
import { EntityBaseComponent } from '@wo-app/shared/models';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-qrcode-scanner-wrapper',
  templateUrl: './qrcode-scanner-wrapper.component.html',
  styleUrls: ['./qrcode-scanner-wrapper.component.scss']
})
export class QRCodeScannerWrapperComponent extends EntityBaseComponent implements OnInit {
  constructor(router: Router, route: ActivatedRoute, impersonationService: ImpersonationService, logger: NGXLogger) {
    super(EntityGlobals.QRCODESCANNER, router, route, impersonationService, logger);
  }

  ngOnInit(): void {}
}
