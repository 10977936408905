import { Component, forwardRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { LocationService } from '@wo-api/index';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-phone-number',
  templateUrl: './phone-number.component.html',
  styleUrls: ['./phone-number.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhoneNumberComponent),
      multi: true
    }
  ]
})
export class PhoneNumberComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor {
  ngOnDestroy(): void {}
  ngOnChanges(changes: SimpleChanges): void {}
  ngOnInit(): void {}
  public maskOnFocus: boolean = true;
  public prompt: string = '';
  public mask: string = '+19999999999';
  public promptPlaceholder: string = '_';
  public includeLiterals: boolean = true;

  form: FormGroup;
  originalFormState: FormGroup;
  formValue: string = '';

  @Input() control!: AbstractControl;
  @Input() formControlName: string = '';
  @Input() elementId: string = '';

  @Input() placeholder: string = ''; // Example of additional input properties

  value: string = '';

  // ControlValueAccessor methods
  onChange = (value: string) => {};
  onTouched = () => {};

  // This method writes the value from the form control into the component
  writeValue(value: string): void {
    this.value = value || '';
  }

  // Registers the change callback to be triggered on input change
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // Registers the touched callback to be triggered on blur
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // Optionally enable/disable the control
  setDisabledState?(isDisabled: boolean): void {
    // Custom logic to handle enabling/disabling the component
  }

  // This method updates the value in the form control when the input changes
  onInputChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    this.value = input.value;
    this.onChange(this.value); // Notify Angular of the value change
  }
}
