export * from './application.service';
import { ApplicationService } from './application.service';
export * from './applicationBuild.service';
import { ApplicationBuildService } from './applicationBuild.service';
export * from './applicationLog.service';
import { ApplicationLogService } from './applicationLog.service';
export * from './authentication.service';
import { AuthenticationService } from './authentication.service';
export * from './bed.service';
import { BedService } from './bed.service';
export * from './building.service';
import { BuildingService } from './building.service';
export * from './cacheTest.service';
import { CacheTestService } from './cacheTest.service';
export * from './category.service';
import { CategoryService } from './category.service';
export * from './checklist.service';
import { ChecklistService } from './checklist.service';
export * from './checklistDrawerDrawer.service';
import { ChecklistDrawerDrawerService } from './checklistDrawerDrawer.service';
export * from './communicationBoardActionItem.service';
import { CommunicationBoardActionItemService } from './communicationBoardActionItem.service';
export * from './communicationBoardItem.service';
import { CommunicationBoardItemService } from './communicationBoardItem.service';
export * from './course.service';
import { CourseService } from './course.service';
export * from './customer.service';
import { CustomerService } from './customer.service';
export * from './dashboard.service';
import { DashboardService } from './dashboard.service';
export * from './drug.service';
import { DrugService } from './drug.service';
export * from './emailTemplates.service';
import { EmailTemplatesService } from './emailTemplates.service';
export * from './equipment.service';
import { EquipmentService } from './equipment.service';
export * from './equipmentLineItem.service';
import { EquipmentLineItemService } from './equipmentLineItem.service';
export * from './importJob.service';
import { ImportJobService } from './importJob.service';
export * from './labMaintenanceTask.service';
import { LabMaintenanceTaskService } from './labMaintenanceTask.service';
export * from './labRequest.service';
import { LabRequestService } from './labRequest.service';
export * from './labRequestDrug.service';
import { LabRequestDrugService } from './labRequestDrug.service';
export * from './labRequestInventoryItem.service';
import { LabRequestInventoryItemService } from './labRequestInventoryItem.service';
export * from './labRequestPatient.service';
import { LabRequestPatientService } from './labRequestPatient.service';
export * from './labRequestRoom.service';
import { LabRequestRoomService } from './labRequestRoom.service';
export * from './labRequestRoomBed.service';
import { LabRequestRoomBedService } from './labRequestRoomBed.service';
export * from './location.service';
import { LocationService } from './location.service';
export * from './manikinInventoryItem.service';
import { ManikinInventoryItemService } from './manikinInventoryItem.service';
export * from './manikinInventoryItemProcedure.service';
import { ManikinInventoryItemProcedureService } from './manikinInventoryItemProcedure.service';
export * from './manikinMakeAndModel.service';
import { ManikinMakeAndModelService } from './manikinMakeAndModel.service';
export * from './manikinMakeAndModelProcedure.service';
import { ManikinMakeAndModelProcedureService } from './manikinMakeAndModelProcedure.service';
export * from './notification.service';
import { NotificationService } from './notification.service';
export * from './organization.service';
import { OrganizationService } from './organization.service';
export * from './patient.service';
import { PatientService } from './patient.service';
export * from './pickOrder.service';
import { PickOrderService } from './pickOrder.service';
export * from './pickOrderEquipment.service';
import { PickOrderEquipmentService } from './pickOrderEquipment.service';
export * from './pickOrderManikin.service';
import { PickOrderManikinService } from './pickOrderManikin.service';
export * from './pickOrderSupply.service';
import { PickOrderSupplyService } from './pickOrderSupply.service';
export * from './role.service';
import { RoleService } from './role.service';
export * from './room.service';
import { RoomService } from './room.service';
export * from './scenario.service';
import { ScenarioService } from './scenario.service';
export * from './simMaintenanceTask.service';
import { SimMaintenanceTaskService } from './simMaintenanceTask.service';
export * from './skill.service';
import { SkillService } from './skill.service';
export * from './supply.service';
import { SupplyService } from './supply.service';
export * from './supplyLocationQuantity.service';
import { SupplyLocationQuantityService } from './supplyLocationQuantity.service';
export * from './supplyLocationQuantityHistory.service';
import { SupplyLocationQuantityHistoryService } from './supplyLocationQuantityHistory.service';
export * from './task.service';
import { TaskService } from './task.service';
export * from './timesheet.service';
import { TimesheetService } from './timesheet.service';
export * from './user.service';
import { UserService } from './user.service';
export * from './vendor.service';
import { VendorService } from './vendor.service';
export * from './zebraPrinter.service';
import { ZebraPrinterService } from './zebraPrinter.service';
export const APIS = [ApplicationService, ApplicationBuildService, ApplicationLogService, AuthenticationService, BedService, BuildingService, CacheTestService, CategoryService, ChecklistService, ChecklistDrawerDrawerService, CommunicationBoardActionItemService, CommunicationBoardItemService, CourseService, CustomerService, DashboardService, DrugService, EmailTemplatesService, EquipmentService, EquipmentLineItemService, ImportJobService, LabMaintenanceTaskService, LabRequestService, LabRequestDrugService, LabRequestInventoryItemService, LabRequestPatientService, LabRequestRoomService, LabRequestRoomBedService, LocationService, ManikinInventoryItemService, ManikinInventoryItemProcedureService, ManikinMakeAndModelService, ManikinMakeAndModelProcedureService, NotificationService, OrganizationService, PatientService, PickOrderService, PickOrderEquipmentService, PickOrderManikinService, PickOrderSupplyService, RoleService, RoomService, ScenarioService, SimMaintenanceTaskService, SkillService, SupplyService, SupplyLocationQuantityService, SupplyLocationQuantityHistoryService, TaskService, TimesheetService, UserService, VendorService, ZebraPrinterService];
